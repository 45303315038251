import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock2White = (key, ortName) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Regensburg/regensburg_bild_02_04.jpg"}
                           data-glightbox="title: Der Reichssaal im Alten Rathaus Regensburg, 2008; description: .custom-desc1_3; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Regensburg/regensburg_bild_02_04.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Der Reichssaal im Alten Rathaus Regensburg, 2008"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_3">
                            <p className="copy">Abb. Stadt Regensburg, Bilddokumentation</p>
                        </div>
                        <figcaption>
                            Der Reichssaal im Alten Rathaus Regensburg, 2008
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <Container.Facsimile
                            pdf="/facsimile/regensburg/02_05_Sitzordnung/Sitzordnung-des-Fuerstlichen-Kollegiums.pdf"
                            img="/images/Regensburg/regensburg_bild_02_08.jpg"
                            alttext="Sitzordnung im Fürstlichen Kollegium">
                            <figcaption>
                                Sitzordnung im Fürstlichen Kollegium
                                <span className="description">
                              Die Zeichnungen aus dem 18. Jahrhundert zeigen, dass es auch in den Beratungsräumen der einzelnen Kollegien eine festgelegte Sitzordnung gab.
                            </span>
                            </figcaption>
                        </Container.Facsimile>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <Container.Facsimile
                            pdf="/facsimile/regensburg/02_04_Innenansicht/Innenansicht-des-Fuerstlichen-Kollegiums.pdf"
                            img="/images/Regensburg/regensburg_bild_02_07.jpg"
                            alttext="Innenansicht des Fürstlichen Kollegiums, 1722">
                            <figcaption>
                                Innenansicht des Fürstlichen Kollegiums, 1722
                                <span className="description">
                                Nach der Eröffnung des Reichstags und der Vorstellung der Themen durch den Kaiser oder seinen Vertreter zogen
                                sich Kurfürstenrat, Reichsfürstenrat und Städterat in ihre Beratungsräume zurück.
                            </span>
                            </figcaption>
                        </Container.Facsimile>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Regensburg/regensburg_bild_02_06.jpg"}
                           data-glightbox="title: Ordnung der Stände des Heiligen Römischen Reichs, um 1730; description: .custom-desc1_6; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Regensburg/regensburg_bild_02_06.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Ordnung der Stände des Heiligen Römischen Reichs, um 1730"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_6">
                            <p>
                                Die Bezeichnung Reichsstände ist erstmals 1486 nachweisbar und umfasst die
                                Reichsfürsten, die Reichsgrafen, Angehörige der Reichskirche und Vertreter der
                                Reichsstädte, die zusammen mit dem Kaiser das Reich repräsentierten.
                            </p>
                            <p className="copy">
                                Abb. Museen der Stadt Regensburg, G 1940/15
                            </p>
                        </div>
                        <figcaption>
                            Ordnung der Stände des Heiligen Römischen Reichs, um 1730
                            <span className="description">
                                Die Bezeichnung Reichsstände ist erstmals 1486 nachweisbar und umfasst die Reichsfürsten,
                                die Reichsgrafen, Angehörige der Reichskirche und Vertreter der Reichsstädte, die zusammen
                                mit dem Kaiser das Reich repräsentierten.
                            </span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock2White

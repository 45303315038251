import Headerimage from "./Headerimage";
import Novel from "./Novel";
import TextBlock2White from "./TextBlock2White";
import TextBlock1blue from "./TextBlock1Blue";
import TextBlock3Beige from "./TextBlock3Beige";
import TextBlock4Parallax from "./TextBlock4Parallax";
import Video from "./Video";

const Parts = [
    Headerimage,
    TextBlock1blue,
    Novel,
    TextBlock2White,
    TextBlock3Beige,
    TextBlock4Parallax,
    Video,
];

export {Parts};

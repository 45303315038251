import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock4Parallax = (key) => {
    return (
        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen" key={key}>
            <Container.Part background="bg-img parallax-bg parallax-regensburg" key={key}>
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Regensburg/regensburg_bild_02_11.jpg"}
                               data-glightbox="description: .custom-desc1_9; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Regensburg/regensburg_bild_02_11.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Alexander Ferdinand Fürst von Thurn und Taxis, Prinzipalkommissar von 1743/48 bis 1773"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_9">
                                <h2>Alexander Ferdinand Fürst von Thurn und Taxis, Prinzipalkommissar von 1743/48 bis
                                    1773</h2>
                                <p>
                                    Wenn der Kaiser beim Reichstag nicht selbst vor Ort war, wurde er von einem von ihm
                                    eingesetzten Prinzipalkommissar vertreten. Ab 1742 wurde dieses Amt mehrfach von den
                                    Fürsten von Thurn und Taxis übernommen, die deshalb auch ihren Wohnsitz von
                                    Frankfurt
                                    nach Regensburg verlegten. Sie trugen maßgeblich zu einer Blüte des kulturellen
                                    Lebens
                                    in der Reichsstadt bei und richteten unter anderem eine Hofbibliothek ein.
                                </p>
                                <p className="copy">Abb. Museen der Stadt Regensburg, G 2004-3,59</p>
                            </div>
                            <figcaption>
                                Alexander Ferdinand Fürst von Thurn und Taxis, Prinzipalkommissar von 1743/48 bis 1773
                                <span className="description">
                                Wenn der Kaiser beim Reichstag nicht selbst vor Ort war, wurde er von einem von ihm
                                eingesetzten Prinzipalkommissar vertreten. Ab 1742 wurde dieses Amt mehrfach von den
                                Fürsten von Thurn und Taxis übernommen, die deshalb auch ihren Wohnsitz von Frankfurt
                                nach Regensburg verlegten. Sie trugen maßgeblich zu einer Blüte des kulturellen Lebens
                                in der Reichsstadt bei und richteten unter anderem eine Hofbibliothek ein.</span>
                            </figcaption>
                        </figure>
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Regensburg/regensburg_bild_02_14.jpg"}
                               data-glightbox="title: Das Ballhaus am Ägidienplatz, um 1760; description: .custom-desc1_10; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Regensburg/regensburg_bild_02_14.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Das Ballhaus am Ägidienplatz, um 1760"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_10">
                                <p>
                                    Für das politische Geschehen waren informelle Treffen von großer Bedeutung. Die
                                    adeligen
                                    Gesandten wurden daher vom Prinzipalkommissar oft zu Konzerten oder festlichen Essen
                                    eingeladen. Die Anwesenheit der Gesandten führte zur Entwicklung einer beständigen
                                    Theaterkultur in Regensburg. Für die nicht adeligen Gesandten spielten Treffen in
                                    Kaffeehäusern und Gaststätten eine wichtige Rolle.
                                </p>
                                <div className="copy">
                                    Federzeichnung von Gottlieb Romanus Brauser
                                    Abb. Museen der Stadt Regensburg, HV 975
                                </div>
                            </div>
                            <figcaption>
                                Das Ballhaus am Ägidienplatz, um 1760
                                <span className="description">
                                Für das politische Geschehen waren informelle Treffen von großer Bedeutung. Die adeligen
                                Gesandten wurden daher vom Prinzipalkommissar oft zu Konzerten oder festlichen Essen
                                eingeladen. Die Anwesenheit der Gesandten führte zur Entwicklung einer beständigen
                                Theaterkultur in Regensburg. Für die nicht adeligen Gesandten spielten Treffen in
                                Kaffeehäusern und Gaststätten eine wichtige Rolle.
                            </span>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Regensburg/regensburg_bild_02_10.jpg"}
                               data-glightbox="title: „Geschencktraeger an die Reichstagsgesandten“, 1800; description: .custom-desc1_8; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Regensburg/regensburg_bild_02_10.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="„Geschencktraeger an die Reichstagsgesandten“, 1800"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_8">
                                <p>
                                    Als Gebot der Gastfreundschaft war es üblich, dass jeder neu angekommene Gesandte
                                    ein
                                    Willkommensgeschenk der Stadt Regensburg erhielt. In den ersten Jahren des
                                    „Immerwährenden Reichstags“ wurden die Gesandten auch mit kostenlosem Wein und
                                    Konfekt
                                    bewirtet. Ab 1688 wurde das eingestellt – nicht zuletzt wegen der hohen Kosten.
                                </p>
                                <p className="copy">Federzeichnung von Leonhard Bleyer
                                    Abb. Museen der Stadt Regensburg, G 1931/122,61
                                </p>
                            </div>
                            <figcaption>
                                „Geschencktraeger an die Reichstagsgesandten“, 1800
                                <span className="description">
                                Als Gebot der Gastfreundschaft war es üblich, dass jeder neu angekommene Gesandte ein
                                Willkommensgeschenk der Stadt Regensburg erhielt. In den ersten Jahren des
                                „Immerwährenden Reichstags“ wurden die Gesandten auch mit kostenlosem Wein und Konfekt
                                bewirtet. Ab 1688 wurde das eingestellt – nicht zuletzt wegen der hohen Kosten.</span>
                            </figcaption>
                        </figure>
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Regensburg/regensburg_bild_02_12.jpg"}
                               data-glightbox="title: Weinbrunnen mit rotem und weißem Wein anlässlich der Krönung von Ferdinand IV., 1653; description: .custom-desc1_11; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Regensburg/regensburg_bild_02_12.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Weinbrunnen mit rotem und weißem Wein anlässlich der Krönung von Ferdinand IV., 1653"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_11">
                                <p>
                                    Als Repräsentanten ihrer Herrscher gehörte es sich für die Gesandten, großzügige
                                    Feste
                                    zu veranstalten. So unterstrichen sie die Bedeutung der jeweiligen Herrscher. Oft
                                    ließ
                                    man auch die einfache Bevölkerung an den Festlichkeiten teilhaben, etwa durch
                                    öffentlich
                                    aufgestellte Weinbrunnen.
                                </p>
                                <p className="copy">Kupferstich von Martin Zimmermann
                                    Abb. Museen der Stadt Regensburg, G 1979/147
                                </p>
                            </div>
                            <figcaption>
                                Weinbrunnen mit rotem und weißem Wein anlässlich der Krönung von Ferdinand IV., 1653
                                <span className="description">
                                Als Repräsentanten ihrer Herrscher gehörte es sich für die Gesandten, großzügige Feste
                                zu veranstalten. So unterstrichen sie die Bedeutung der jeweiligen Herrscher. Oft ließ
                                man auch die einfache Bevölkerung an den Festlichkeiten teilhaben, etwa durch öffentlich
                                aufgestellte Weinbrunnen.
                            </span>
                            </figcaption>
                        </figure>
                    </div>
                </Container.Row>
            </Container.Part>
        </Container.More>
    )
}

export default TextBlock4Parallax

import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock3Beige = (key, ortName) => {
    return (
        <Container.Part background="beige" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>Der „Immerwährende Reichstag“
                        und das gesellschaftliche <br/>
                        und kulturelle Leben in Regensburg</h2>
                    <div className="inner-container">
                        <blockquote>
                            „[…] jeder kann ungezwungen und nach seinem Geschmack leben […] Dieser ungezwungene,
                            gesellige
                            und abwechselnde Umgang macht auch, dass Fremde, die von großen volkreichen Städten kommen,
                            nach
                            und nach Regensburg so lieb gewinnen, dass sie es ungerne wieder verlassen […]“
                            <span className="author">
                        Jacob Christian Gottlieb Schäffer, Versuch einer medicinischen Ortbeschreibung der Stadt Regensburg, 1787
                    </span>
                        </blockquote>
                    </div>
                </div>
            </Container.Row>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Regensburg/regensburg_bild_02_13.jpg"}
                           data-glightbox="title: Einzug des Prinzipalkommissars Kardinal Guidobald von Thun, 1662; description: .custom-desc1_7; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Regensburg/regensburg_bild_02_13.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Einzug des Prinzipalkommissars Kardinal Guidobald von Thun, 1662"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_7">
                            <p>
                                Die Reichstage bedeuteten für Regensburg einen erheblichen organisatorischen Aufwand.
                                Zeitweise mussten mehrere Tausend Teilnehmer und Gäste beherbergt werden. Für das Jahr
                                1594 wird der Hofstaat der elf persönlich anwesenden Kurfürsten mit rund 4000 Personen
                                angegeben. Allerdings waren nicht alle Reichstage so groß und das Gefolge hielt sich oft
                                nur kurzzeitig in der Stadt auf.
                                Neben den Gesandten aus dem ganzen Reich kamen auch zahlreiche Beobachter aus dem
                                Ausland.
                            </p>
                            <p className="copy">Abb. Museen der Stadt Regensburg, G 1956/83,1</p>
                        </div>
                        <figcaption>
                            Einzug des Prinzipalkommissars Kardinal Guidobald von Thun, 1662
                            <span className="description">
                                Die Reichstage bedeuteten für Regensburg einen erheblichen organisatorischen Aufwand.
                                Zeitweise mussten mehrere Tausend Teilnehmer und Gäste beherbergt werden. Für das Jahr
                                1594 wird der Hofstaat der elf persönlich anwesenden Kurfürsten mit rund 4000 Personen
                                angegeben. Allerdings waren nicht alle Reichstage so groß und das Gefolge hielt sich oft
                                nur kurzzeitig in der Stadt auf. Neben den Gesandten aus dem ganzen Reich kamen auch
                                zahlreiche Beobachter aus dem Ausland.
                            </span>
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox karte">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Regensburg/Karte-1648.png"}
                           data-glightbox="title: Das Heilige Römische Reich Deutscher Nation, 1648;">
                            <StaticImage src="../../../../static/images/Regensburg/Karte-1648.png" width={335} layout="fixed" placeholder="none"
                                         alt="Das Heilige Römische Reich Deutscher Nation, 1648"/>
                        </a>
                        <figcaption>
                            Das Heilige Römische Reich Deutscher Nation, 1648
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock3Beige

import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Headerimage = (key, ortName) => {

    return (
        <Container.HeaderPart background={ortName} key={key}>
            <div className="headline">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>
                                1594/1663-1806<br/>
                                Regensburg<br/>
                                Altes Rathaus<br/>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="box">
                            <ul>
                                <li>Föderale Strukturen</li>
                                <li>Aushandlungsprozesse</li>
                                <li>Mitbestimmung</li>
                            </ul>
                            <Container.If env="t">
                                <p>
                                    Der „Immerwährende Reichstag“ war die Vertretung der Reichsstände und tagte als
                                    Gesandtenkongress von 1663 bis 1806 dauerhaft in Regensburg. Der Kaiser und die
                                    Reichsstände sowie Vertreter europäischer Staaten verhandelten hier Fragen, die
                                    das Reich als Ganzes betrafen.
                                </p>
                            </Container.If>
                            <Container.If env="w">
                                <p> Der „Immerwährende Reichstag“ war die Vertretung der Reichsstände und tagte als
                                    Gesandtenkongress von 1663 bis 1806 dauerhaft in Regensburg.</p>
                                <p> Der Kaiser und die Reichsstände sowie Vertreter europäischer Staaten verhandelten
                                    hier
                                    Fragen,
                                    die das Reich als Ganzes und die politischen Verhältnisse in Europa betrafen. Der
                                    Reichstag
                                    setzte mit sog. Reichsschlüssen Recht im Heiligen Römischen Reich Deutscher
                                    Nation.</p>
                            </Container.If>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Regensburg/regensburg_bild_02_09.jpg"}
                                   data-glightbox="title: Auffahrt der Gesandten vor dem Regensburger Rathaus, 1729; description: .custom-desc1_1; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Regensburg/regensburg_bild_02_09.jpg" width={335} layout="fixed" placeholder="none"
                                                 alt="Auffahrt der Gesandten vor dem Regensburger Rathaus, 1729"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_1">
                                    <p>
                                        Der Kaiser und die Fürsten konnten nicht dauerhaft in Regensburg sein und
                                        schickten deshalb ihre Gesandten. Die Sitzungen folgten einem festen
                                        Zeremoniell. Die Gesandten kamen je nach Vermögen mit der Kutsche, in der Sänfte
                                        oder zu Fuß. Neben der politischen Tätigkeit unterhielten sie sich und die
                                        Regensburger Bürger mit festlichen Veranstaltungen.
                                    </p>
                                    <p className="copy">
                                        Kupferstich von Andreas Geyer <br/>
                                        Abb. Museen der Stadt Regensburg, G 1979/26
                                    </p>
                                </div>
                                <figcaption>
                                    Auffahrt der Gesandten vor dem Regensburger Rathaus, 1729
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Regensburg/regensburg_bild_02_05.jpg"}
                                   data-glightbox="title: Darstellung der Reichsproposition, Januar 1663; description: .custom-desc1_2; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Regensburg/regensburg_bild_02_05.jpg" width={335} layout="fixed" placeholder="none"
                                                 alt="Darstellung der Reichsproposition, Januar 1663"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_2">
                                    <p>
                                        An der ausführlichen Legende des Kupferstichs lässt sich die hierarchische
                                        Sitzordnung im Reichssaal ablesen. An der Stirnseite unter einem Baldachin war
                                        der Platz des Kaisers bzw. seines Stellvertreters, zu seiner Rechten und Linken
                                        sowie ihm gegenüber saßen die Kurfürsten, auf den Bänken an den beiden
                                        Seitenwänden die geistlichen und weltlichen Fürsten sowie auf den Bänken hinten
                                        im Saal die Vertreter der Reichsstädte.
                                    </p>
                                    <p className="copy">
                                        Abb. Museen der Stadt Regensburg, G 2008/15
                                    </p>
                                </div>
                                <figcaption>
                                    Darstellung der Reichsproposition, Januar 1663
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </Container.HeaderPart>
    )
}

export default Headerimage

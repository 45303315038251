import * as React from 'react'
import * as Container from "../../container/container";

const TextBlock1blue = (key) => {
    return (
        <Container.Part background="blue" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>„Ist doch aller Tage rot (Rat)<br/> <span>&nbsp;</span> von morgens bis imbiss <br/> und von
                        imbiss bis nacht“</h2>
                    <div className="inner-container">
                        <p> Das Alte Rathaus in Regensburg war von 1663 bis 1806 als Schauplatz des „Immerwährenden
                            Reichstags“ ein wichtiger politischer Ort im Heiligen Römischen Reich Deutscher Nation und
                            in Europa. Zuvor hatte der Kaiser den Reichstag unregelmäßig in verschiedenen Städten
                            einberufen, ab 1594 fand er in Regensburg statt. Die Stadt an der Donau hatte eine
                            verkehrsgünstige Lage und Kirchen für katholische wie für protestantische Teilnehmer.


                        </p>
                        <p>
                            1663 war der Reichstag zusammengekommen, um über militärische Fragen und über die
                            Gesetzgebung zu verhandeln. Bevor es eine Einigung gab, entstanden neue Fragen und die
                            Versammlung wurde zum „Immerwährenden Reichstag“. Der Wandel zu einer dauerhaften
                            Versammlung zeigt die wachsende Macht der Fürsten, die nicht mehr nur auf Wunsch des Kaisers
                            zusammenkamen. Entscheidungen über Steuern, Bündnisse, Militär und Gesetze wurden nun von
                            den Reichsständen und dem Kaiser gemeinsam getroffen. Kaiser und Fürsten wurden in der Regel
                            von ihren Gesandten vertreten.
                        </p>

                        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">

                            <p>
                                Im Reichstag mussten Kurfürstenrat, Reichsfürstenrat und Städterat zu einer gemeinsamen
                                Entscheidung kommen. Die drei Kollegien berieten sich und stimmten jeweils einzeln ab.
                                Für eine Einigung wurden oft Kompromisse oder Änderungsvorschläge ausgehandelt.
                            </p>
                            <p>
                                Das Zusammentreffen von Gesandten aus den Teilen des Reichs sowie die Beratungs- und
                                Entscheidungsprozesse können als Vorform des Parlamentarismus betrachtet werden.
                                Allerdings waren die Mitglieder nicht gewählt, sondern delegiert.
                            </p>
                            <p>
                                Die Stände vertraten sich selbst und nicht unmittelbar ihre Bevölkerung. Dies ist ein
                                wichtiger Unterschied zu modernen Parlamenten.
                            </p>
                        </Container.More>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock1blue
